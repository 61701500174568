import React from 'react'
import _ from 'lodash'

import CoreRenderFunction from 'DisplayCore'
import { handleNestedObjectKey } from 'utils'

const PackageProductList = props => {
  let {
    dataKey,
    children
  } = props.item

  return(
    <>
      {
        handleNestedObjectKey( props, dataKey )?.map( val => ( //data value
          children?.map( child => { //style structure
            const updateSelectedVariantFunc = value => {
              let tmp = _.cloneDeep( props.selectedProductVariants )
              tmp[ val.site_product_id ] = { ...tmp[ val.site_product_id ], ...value }
              props.updateSelectedProductVariants( tmp )
            }
            return (
              <CoreRenderFunction 
                { ...props }
                item={ child }
                selectedValue={ { ...val.site_product, quantity: val.quantity } }
                productVariants={ val.site_product.site_product_variants }
                productVariantCombination={ val.site_product.site_product_variant_combinations }
                updateSelectedVariantFunc={ updateSelectedVariantFunc }
                selectedVariant={ props.selectedProductVariants[ val.site_product_id ] || { L1: null, L2: null } }
                isInvalid={ props.variantError[ val.site_product_id ] }/>
            )
          })
        ))
      }
    </>
  )
}

export default PackageProductList