import _ from 'lodash'
import { Component } from 'react'
import { connect } from 'react-redux'

import { Get, Post, Put } from 'utils/axios'
import { handleErrorMsg, getSelectedLanguageContent } from 'utils'
import { requestError, requestSuccess } from 'utils/requestHandler'
import { addLoadingContent, removeLoadingContent } from 'reducers/LoadingOverlay'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      contentObj: {
        content_json: { en: '', zh: '', ms: '' }
      },
      contentString: '',
      showEditor: false
    }

    componentDidMount() {
      this.getContent()
    }

    onChangeHOC = ( key, val ) => this.setState({[ key ]: val })
    load = param => {
      if( param ){
        this.props.addLoadingContent()
      } else {
        this.props.removeLoadingContent()
      }
    }

    getContent = () => Get(
      `/sites/${ this.props.siteInfo.id }/by_page/contact-us`,
      this.getContentSuccess,
      this.getContentError,
      this.load
    )
    getContentSuccess = payload => {
      if( !_.isEmpty( payload[ 0 ]) && !_.isEmpty( payload[ 0 ][ 'content_json' ])) {
        this.setState({ contentObj: payload[ 0 ], contentString: payload[ 0 ][ 'content_json' ][ this.props.selectedLanguage ] || '' })
      }
    }
    getContentError = error => requestError( handleErrorMsg( error ))

    addContent = data => Post(
      `/sites/${ this.props.siteInfo.id }/layouts`,
      data,
      this.addContentSuccess,
      this.addContentError,
      this.load
    )
    addContentSuccess = payload => requestSuccess( getSelectedLanguageContent("{{CONTENT_IS_ADDED}}") )
    addContentError = error => requestError( handleErrorMsg( error ))

    updateContent = ( id, data ) => Put(
      `/sites/${ this.props.siteInfo.id }/layouts/${ id }`,
      data,
      this.updateContentSuccess,
      this.updateContentError,
      this.load
    )
    updateContentSuccess = payload => {
      requestSuccess( getSelectedLanguageContent("{{CONTENT_IS_UPDATED}}") )
      this.getContent()
    }
    updateContentError = error => requestError( handleErrorMsg( error ))

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onChangeHOC={ this.onChangeHOC }
          getContent={ this.getContent }
          addContent={ this.addContent }
          updateContent={ this.updateContent }
          contentObj={ this.state.contentObj }
          contentString={ this.state.contentString }
          showEditor={ this.state.showEditor }
        />
      )
    }
  }

  const mapStateToProps = state => ({
    data: state,
    colors: state.ClientReducer.clientInfo.styles?.colors,
    selectedLanguage: state.ProfileReducer.profile.preferred_language,
    profile: state.ProfileReducer.profile,
    clientInfo: state.ClientReducer.clientInfo,
    siteInfo: state.ClientReducer.siteInfo
  })
  return connect( mapStateToProps, { addLoadingContent, removeLoadingContent } )( WithHOC )
}

export default HOC
