import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import CoreRenderFunction from 'DisplayCore'
import { removePendingPayment } from "reducers/ShopCart"
import { paymentSuccessfulLayout } from 'PageLayout/Registration/PaymentSuccessful'

const PaymentSuccessful = props => {
  const [ userEmail, updateUserEmail ] = useState("")
  const [ clientUser, updateClientUser ] = useState({})
  const [ createdOrder, updateCreatedOrder ] = useState({})

  useEffect(() => {
    let pendingPayment = _.find( props.pendingPayments, { value: window.location.hash.split('/').at(-1) } )
    if( pendingPayment ){
      updateUserEmail( pendingPayment.userEmail )
      updateClientUser({
        ...pendingPayment.clientUser, 
        loginID: props.siteInfo.login_by === 'mobile_number' 
          ? `${pendingPayment.clientUser.dialing_code_prefix}${pendingPayment.clientUser.mobile_number}` 
          : pendingPayment.clientUser.username 
      })
      updateCreatedOrder( pendingPayment.order )
      props.removePendingPayment( { value: window.location.hash.split('/').at(-1), status: false } )
    }
  }, [])

  return(
    <CoreRenderFunction 
      { ...props } 
      item={ paymentSuccessfulLayout.content }
      userEmail={ userEmail }
      clientUser={ clientUser }
      createdOrder={ createdOrder }/>
  )
}

const mapStateToProps = state => ({
  data: state,
  clientInfo: state.ClientReducer.clientInfo,
  siteInfo: state.ClientReducer.siteInfo,
  colors: state.ClientReducer.clientInfo.styles?.colors,
  selectedLanguage: state.ProfileReducer.profile.preferred_language,
  pendingPayments: state.ShopCartReducer.pendingPayment
})

export default connect( mapStateToProps, { removePendingPayment } )( PaymentSuccessful )