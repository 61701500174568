import React, { useState } from "react"
import _ from "lodash"
import moment from "moment"
import { Modal, Button } from "antd"
import parse from "html-react-parser"

import { getItem, clearItem } from "utils/tokenStore"
import { getSelectedLanguageContent } from "utils"

import WithHOC from "./action"

const TermsAndConditions = (props) => {
  let {
    isOpen,
    onCancel = () => {},
    profile,
    siteInfo,
    acceptTNC,
    setUserProfile,
  } = props
  const [isScrolledToBottom, updateIsScrolledToBottom] = useState(false)

  const checkScrolledToBottom = (event) => {
    const target = event.target
    if (target.scrollHeight - target.scrollTop <= target.clientHeight + 2) {
      updateIsScrolledToBottom(true)
    }
  }

  return (
    <Modal
      open={isOpen}
      width={800}
      footer={false}
      closeIcon={!_.isEmpty(profile.date_accepted_tnc)}
      onCancel={() => {
        if (_.isEmpty(profile.date_accepted_tnc)) {
          return
        } else {
          onCancel()
        }
      }}
    >
      <div
        className="px-2 my-3"
        style={{ height: "65vh", overflowY: "scroll" }}
        onScroll={checkScrolledToBottom}
      >
        {parse(JSON.parse(siteInfo.terms_and_conditions_content))}
      </div>
      {profile.date_accepted_tnc ? (
        <div
          className="text-right"
          style={{ color: "#b9b9b9", fontSize: "0.75rem" }}
        >
          {getSelectedLanguageContent("{{MEMBER_ACCEPTED_TNC_ON}}")}{" "}
          {moment(profile.date_accepted_tnc).format("YYYY-MM-DD, hh:mm:ss a")}
        </div>
      ) : (
        <>
          <div style={{color: "#999999"}}>{getSelectedLanguageContent("{{SCROLL_TO_AGREE_MESSAGE}}")}</div>
          <div style={{color: "#999999"}}>{getSelectedLanguageContent("{{CONFIRM_ACCEPTANCE_MESSAGE}}")}</div>
          <div className="d-flex justify-content-end">
            {/* <Button
              className="mr-2"
              onClick={() => {
                if (getItem("IMPERSONATE_TOKEN")) {
                  clearItem("IMPERSONATE_TOKEN")
                  window.location.reload(true)
                } else {
                  setUserProfile({})
                  clearItem("TECHBASE_TOKEN")
                }
              }}
            >
              Disagree
            </Button> */}
            <Button disabled={!isScrolledToBottom} onClick={acceptTNC}>
              {getSelectedLanguageContent("{{AGREE}}")}
            </Button>
          </div>
        </>
      )}
    </Modal>
  )
}

export default WithHOC(TermsAndConditions)
