import React from 'react'
import { Progress } from 'antd'
import { applyColor } from 'utils/apply-color'
import { handleNestedObjectKey } from 'utils'

const TechBaseProgress = props => {
  let {
    percent,
    showInfo,
    strokeColor,
    style,
    dataKey
  } = props.item

  return(
    <Progress 
      percent={ percent || handleNestedObjectKey( props, dataKey )}
      showInfo={ showInfo }
      strokeColor={ applyColor( strokeColor, props.colors )}
      style={ style }
    />
  )
}

export default TechBaseProgress
