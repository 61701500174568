import React, { useRef, useEffect } from "react"
import { message } from "antd"
import _ from "lodash"

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import WithHOC from "./action"
import CoreRenderFunction from "DisplayCore"
import { LoginLayout } from "PageLayout/Login"
import { getItem, clearItem } from 'utils/tokenStore'

const Login = props => {
  let {
    data,
    profile,
    siteInfo,
    onLoadLogin,
    loginRequest,
    setUserProfile
  } = props

  useEffect(() => {
    if( getItem( 'TECHBASE_TOKEN' )) {
      return props.history.push( '/dashboard' );
    } else {
      clearItem( 'IMPERSONATE_TOKEN' )
      if( !_.isEmpty( profile )){
        setUserProfile({}) 
      }
    }
  })
  
  let captchaRef = useRef()

  const onSubmitForm = e => {
    if( captchaRef.current.validateCaptcha( e.captcha ) ){
      loginRequest({ username: e.username, password: e.password, siteDomain: data.ClientReducer.siteInfo.domain })
    } else {
      message.error("Invalid Captcha")
    }
  }

  const hideComponent = ( actionKey ) => {
    switch( actionKey ){
      case 'isNotLoginByUsername':
        return siteInfo.login_by !== 'username'
      case 'isNotLoginByMobileNumber':
        return siteInfo.login_by !== 'mobile_number'
      default:
    }
  }

  return (
    <>
      <CoreRenderFunction 
        { ...props } 
        item={ LoginLayout }
        hideComponent={ hideComponent }
        onSubmitForm={ onSubmitForm } 
        captchaRef={ captchaRef }/>
      {
        onLoadLogin && <LoadingOverlay />
      }
    </>
  )
}

export default WithHOC( Login )
