import React from "react"
import _ from 'lodash'

import WithHOC from "./action"
import CoreRenderFunction from "DisplayCore"
import { ForgotSecurityPinLayout } from "PageLayout/ForgotSecurityPin"

const ForgotSecurityPin = props => {

  const hideComponent = ( actionKey ) => {
    switch( actionKey ){
      default:
        return
    }
  }

  return (
    <CoreRenderFunction 
      { ...props } 
      item={ ForgotSecurityPinLayout }
      hideComponent={ hideComponent }/>
  )
}

export default WithHOC( ForgotSecurityPin )