import React, { Component } from "react"
import { connect } from "react-redux"

class Logo extends Component {
  render = () => {
    return (
      <div className="app-header__logo">
        <img src={ this.props.logoImage } alt="logo" />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  logoImage: state.ClientReducer.clientInfo.logo_image_url
})

export default connect( mapStateToProps )( Logo )
