import React from 'react'
import { Row } from 'antd'

import CoreRenderFunction from 'DisplayCore'
import { applyColor } from 'utils/apply-color'

const ZitronRow = props => {
  let {
    style,
    children,
    className,
    gutter = 0,
    align = 'top',
    justify = 'start',
    wrap = true
  } = props.item
  return(
    <Row
      gutter={ gutter }
      align={ align }
      justify={ justify }
      wrap={ wrap }
      style={ applyColor( style, props.colors ) || {} } 
      className={ className || "" }>
      {
        children?.map( child => {
          return <CoreRenderFunction { ...props } item={ child }/>
        })
      }
    </Row>
  )
}

export default ZitronRow