import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import { Put } from 'utils/axios'
import { addLoadingContent, removeLoadingContent } from 'reducers/LoadingOverlay'
import { requestError } from 'utils/requestHandler'
import { setUserProfile } from 'reducers/profile'
import { handleErrorMsg } from "utils"

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {}

    load = param => {
      if( param ){
        this.props.addLoadingContent()
      } else {
        this.props.removeLoadingContent()
      }
    }

    updateClientUser = dataToSubmit => Put(
      `/client_users/${ dataToSubmit.id }`,
      dataToSubmit,
      () => this.updateClientUserSuccess( dataToSubmit ),
      this.updateClientUserError,
      this.load
    )
    updateClientUserSuccess = dataToSubmit => {
      window.location.reload( false )
    }
    updateClientUserError = error => requestError( handleErrorMsg(error) )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          updateClientUser={ this.updateClientUser }/>
      )
    }
  }
  const mapStateToProps = state => ({ 
    data: state,
    selectedLanguage: state.ProfileReducer.profile.preferred_language,
    profile: state.ProfileReducer.profile,
    colors: state.ClientReducer.clientInfo.styles?.colors,
    clientInfo: state.ClientReducer.clientInfo
  })
  return connect( mapStateToProps, { setUserProfile, addLoadingContent, removeLoadingContent } )( WithHOC )
}

export default HOC