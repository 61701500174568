import React from 'react'
import { Steps } from 'antd'

import CoreRenderFunction from 'DisplayCore'
import { applyColor } from 'utils/apply-color'
import { handleNestedObjectKey, getContraColor } from 'utils'

const TechBaseSteps = props => {
  let {
    style,
    className,
    children,
    dataKey,
    stepsItem,
    hideStep
  } = props.item

  return(
    <>
      <style>
        { applyColor(
          `.ant-steps-item-process .ant-steps-item-icon {
            background-color: $primary !important;
            border-color: $primary !important;
          }
          .ant-steps-item-finish .ant-steps-item-icon {
            background-color: ${ getContraColor( applyColor( "$primary" ), 200, '#e6f4ff', '#6a6b6c' ) } !important;
            border-color: ${ getContraColor( applyColor( "$primary" ), 200, '#e6f4ff', '#6a6b6c' ) } !important;
          }
          .ant-steps-item-process .ant-steps-item-icon >.ant-steps-icon {
            color: ${ getContraColor( applyColor( "$primary" ), 200, '#e6f4ff' ) } !important;
          }
          .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon {
            color: $primary !important;
          }
          .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after{ background-color: $primary !important; }
        }`,
          props.colors
        )}
      </style>
      {
        !props.hideComponent( hideStep ) && (
          <Steps
            style={ style }
            className={ className }
            current={ handleNestedObjectKey( props, dataKey ) }
            items={ stepsItem.map( val => { 
              return { 
                title: val.title ? <CoreRenderFunction { ...props } item={ val.title }/> : "", 
                description: val.description ? <CoreRenderFunction { ...props } item={ val.description }/> : "", 
                subTitle: val.subTitle ? <CoreRenderFunction { ...props } item={ val.subTitle }/> : ""
              }
            })}/>
        )
      }
      <CoreRenderFunction { ...props } item={ children[ handleNestedObjectKey( props, dataKey ) || 0 ] }/>
    </>
  )
}

export default TechBaseSteps