import React from 'react'
import { Tag } from 'antd'
import CoreRenderFunction from 'DisplayCore'
import { applyColor } from 'utils/apply-color'

const TechBaseTag = props => {
  let {
    color,
    style,
    children
  } = props.item

  return(
    <Tag
      color={ applyColor( color, props.colors )}
      style={ style }
    >
      {
        <CoreRenderFunction
          { ...props }
          item={ children }
        />
      }
    </Tag>
  )
}

export default TechBaseTag
