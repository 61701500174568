export const SET_PROFILE = 'GET_PROFILE'
export const SET_CLIENT_COUNTRY = 'SET_CLIENT_COUNTRY'
export const SET_COUNTRY = 'SET_COUNTRY'
export const SET_PERMISSIONS = 'SET_PERMISSIONS'

const initialState = {
  profile: {
    preferredLanguage: "en"
  },
  country: {},
  clientCountry: {},
  permissions: []
}

export const setPermissions = payload => ({
  type: SET_PERMISSIONS,
  payload,
})

export const setUserProfile = payload => ({
  type: SET_PROFILE,
  payload,
})
export const setClientCountry = payload => ({
  type: SET_CLIENT_COUNTRY,
  payload,
})
export const setCountry = payload => ({
  type: SET_COUNTRY,
  payload,
})

export default ( state = initialState, action ) => {
  switch( action.type ) {
    case SET_PROFILE: 
      return {
        ...state,
        profile: action.payload
      }
    case SET_CLIENT_COUNTRY:
      return {
        ...state,
        clientCountry: action.payload
      }
    case SET_COUNTRY: 
      return {
        ...state,
        country: action.payload
      }
    
    case SET_PERMISSIONS:
      return {
        ... state,
        permissions: action.payload
      }
    default: return state
  }
}