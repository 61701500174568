import React, { useState, useEffect } from "react"
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Thumbs } from 'swiper'
import _ from 'lodash'

import { handleNestedObjectKey } from 'utils'
import "./index.scss"

const ImageSlider = props => {
  let {
    dataKey,
    urlKey
  } = props.item
  const [activeThumb, setActiveThumb] = useState()
  const [ slides, updateSlides ] = useState([])
  const [ packageImages, updatePackageImages ] = useState([])
  useEffect(() => {
    let tmp = handleNestedObjectKey( props, dataKey )
    if( !_.isEqual( tmp, packageImages ) ){
      updatePackageImages( _.filter( tmp, val => val !== null ) )
    }
  }, [ handleNestedObjectKey( props, dataKey ) ])

  useEffect(() => {
    if( packageImages && !_.isEmpty( packageImages )){
      if( !_.isEqual( packageImages, slides ) ){
        updateSlides( packageImages )
      }
    } else {
      updateSlides([{ url: props.data.ClientReducer.clientInfo.logo_image_url }])
    }
  }, [ packageImages ] )

  return (
    <>
      <Swiper
        loop={true}
        spaceBetween={10}
        navigation={true}
        modules={[Navigation, Thumbs]}
        grabCursor={true}
        thumbs={{ swiper: activeThumb }}
        className='product-images-slider mb-2'>
        {
          slides?.map( ( data, index ) => (
            <SwiperSlide key={index}>
              <img src={ handleNestedObjectKey( data, urlKey ) || data.url } alt="product images" style={{ objectFit: "contain", width: "100%", height: "100%" }} />
            </SwiperSlide>
          ))
        }
      </Swiper>
      <Swiper
        onSwiper={setActiveThumb}
        loop={ slides?.length > 4 ? true : false }
        spaceBetween={10}
        slidesPerView={4}
        modules={[Navigation, Thumbs]}
        className='product-images-slider-thumbs'>
        {
          slides?.map( ( data, index ) => (
            <SwiperSlide key={index}>
              <div className="product-images-slider-thumbs-wrapper">
                <img src={ handleNestedObjectKey( data, urlKey ) || data.url } alt="product images" style={{ objectFit: "contain", width: "100%", height: "100%" }} />
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </>
  )
}

export default ImageSlider