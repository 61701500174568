export const GET_CLIENT_COUNTRIES = 'GET_CLIENT_COUNTRIES'
export const GET_CLIENT = 'GET_CLIENT'
export const GET_SITE = 'GET_SITE'
export const GET_SITE_MODULE = "GET_SITE_MODULE"

const initialState = {
  clientCountries: [],
  clientInfo: {},
  siteInfo: {},
  siteModules: []
}

export const setClientCountries = payload => ({
  type: GET_CLIENT_COUNTRIES,
  payload,
})

export const setClient = payload => ({
  type: GET_CLIENT,
  payload,
})

export const setSite = payload => ({
  type: GET_SITE,
  payload,
})

export const setSiteModule = payload => ({
  type: GET_SITE_MODULE,
  payload,
})

export default ( state = initialState, action ) => {
  switch( action.type ) {
    case GET_CLIENT_COUNTRIES: 
      return {
        ...state,
        clientCountries: action.payload
      }
    case GET_CLIENT: 
      return {
        ...state,
        clientInfo: action.payload
      }
    case GET_SITE: 
      return {
        ...state,
        siteInfo: action.payload
      }
    case GET_SITE_MODULE: 
      return {
        ...state,
        siteModules: action.payload
      }
    default: return state
  }
}