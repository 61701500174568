import React from "react";
import { Modal, Button } from "reactstrap";
import { IoMdClose } from "react-icons/io";

import "./index.scss";

export default function NeumoreModal({
  isOpen,
  fullScreen,
  children,
  className,
  size,
  style,

  onClose,
}) {
  return (
    <>
      <Modal
        centered={true}
        className={`${className || ""} techbase-dialog ${
          fullScreen ? "techbase-dialog-full_screen" : ""
        }`}
        isOpen={isOpen}
        size={size}
        style={{ ...style, position: "relative" }}
      >
        {onClose && (
          <Button
            onClick={() => onClose()}
            color={"danger"}
            style={{
              position: "absolute",
              top: -15,
              right: -5,
              zIndex: 1,
              padding: 0,
              width: 30,
              height: 30,
            }}
          >
            <IoMdClose style={{ width: 20, height: 20 }} />
          </Button>
        )}
        {children}
      </Modal>
    </>
  );
}
