import React, { Component } from "react"
import { connect } from "react-redux"
import _ from "lodash"

import { setUserProfile } from 'reducers/profile'
import { handleErrorMsg } from "utils"
import { requestError, requestSuccess } from "utils/requestHandler"
import {
  addLoadingContent,
  removeLoadingContent,
} from "reducers/LoadingOverlay"
import { getProfile } from "actions/profile"
import { Put } from "utils/axios"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {}
    load = (param) => {
      if (param) {
        this.props.addLoadingContent()
      } else {
        this.props.removeLoadingContent()
      }
    }
    onChangeCheckoutHOC = (key, val) => this.setState({ [key]: val })

    acceptTNC = () =>
      Put(
        `/client_users/accept-tnc`,
        {},
        this.acceptTNCSuccess,
        this.acceptTNCError,
        this.load
      )
    acceptTNCSuccess = (payload) => {
      this.props.getProfile(this.props.data.ClientReducer)
      requestSuccess(payload.message)
    }
    acceptTNCError = (error) => requestError(handleErrorMsg(error))

    render = () => {
      return <WrappedComponent {...this.props} acceptTNC={this.acceptTNC} />
    }
  }

  const mapStateToProps = (state) => ({
    data: state,
    siteInfo: state.ClientReducer.siteInfo,
    clientInfo: state.ClientReducer.clientInfo,
    clientCountries: state.ClientReducer.clientCountries,
    profile: state.ProfileReducer.profile,
  })
  return connect(mapStateToProps, {
    getProfile,
    setUserProfile,
    addLoadingContent,
    removeLoadingContent,
  })(WithHOC)
}

export default HOC
