import React from "react"
import Loader from "react-loaders"
import { connect } from 'react-redux'

const LoadingOverlay = props => {
  if( props.clientInfo?.loading_overlay?.url ){
    return (
      <div
        className="loader-container"
        style={{
          position: "fixed",
          backgroundColor: "#ffffff",
          top: 0,
          left: 0,
          zIndex: 999,
        }}
      >
        <div className="loader-container-inner">
          <div className="text-center">
            <img style={{ width: "200px" }} src={ props.clientInfo.loading_overlay.url }/>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div
        className="loader-container"
        style={{
          position: "fixed",
          backgroundColor: "rgba(0,0,0,0.3)",
          top: 0,
          left: 0,
          zIndex: 999,
        }}
      >
        <div className="loader-container-inner">
          <div className="text-center">
            <Loader type="ball-pulse-rise" />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  clientInfo: state.ClientReducer.clientInfo
})

export default connect( mapStateToProps )( LoadingOverlay )
