import React, { useState, useEffect } from 'react'
import { sha256 } from 'js-sha256'
import _ from 'lodash'

import getDomainURL from 'utils/api'
import CoreRenderFunction from 'DisplayCore'
import { handleNestedObjectKey } from 'utils'

const PaymentGateway = props => {
  let {
    id,
    dataKey,
    children
  } = props.item
  
  const [ keyCode, updateKeyCode ] = useState({})
  let paymentInfo = handleNestedObjectKey( props, dataKey )

  useEffect(() => {
    let tmp = _.find( props.clientInfo.payment_gateway, { name: "ipay88" })
    if( tmp ){
      updateKeyCode( tmp )
    }
  }, [ props.clientInfo ])

  if( !_.isEmpty( paymentInfo ) ){
    return (
      <form
        id={ id }
        method={'post'}
        name="ePayment"
        action="https://payment.ipay88.com.my/ePayment/entry.asp">
        <div className="form-group" hidden>
          <label style={{ fontSize: '14px', fontWeight: '500' }}>{'Merchant Code'}</label>
          <input
            readOnly="readonly"
            name={'MerchantCode'}
            className="form-control"
            style={{ width: '100%', height: '50px' }}
            value={ keyCode.username }/>
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: '14px', fontWeight: '500' }}>{'Payment ID'}</label>
          <input
            readOnly="readonly"
            name={'PaymentId'}
            className="form-control"
            style={{ width: '100%', height: '50px' }}
            value={ props.clientInfo.environment !== "production" ? "" : paymentInfo.paymentId } />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: '14px', fontWeight: '500' }}>{'Ref No'}</label>
          <input
            readOnly="readonly"
            name={'RefNo'}
            className="form-control"
            style={{ width: '100%', height: '50px' }}
            value={ paymentInfo.refNo } />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: '14px', fontWeight: '500' }}>{'Amount'}</label>
          <input
            readOnly="readonly"
            name={'Amount'}
            className="form-control"
            style={{ width: '100%', height: '50px' }}
            value={ (props.clientInfo.environment !== "production" ? 1 : paymentInfo.amount).toFixed(2) } />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: '14px', fontWeight: '500' }}>{'Currency'}</label>
          <input
            readOnly="readonly"
            name={'Currency'}
            className="form-control"
            style={{ width: '100%', height: '50px' }}
            value={'MYR'} />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: '14px', fontWeight: '500' }}>{'Product Description'}</label>
          <input
            readOnly="readonly"
            name={'ProdDesc'}
            className="form-control"
            style={{ width: '100%', height: '50px' }}
            value={ paymentInfo.itemDescription || "N/A" } />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: '14px', fontWeight: '500' }}>{'Customer Name'}</label>
          <input
            required
            name={'UserName'}
            className="form-control"
            style={{ width: '100%', height: '50px' }}
            value={ paymentInfo.fullName }
            placeholder="John Doe" />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: '14px', fontWeight: '500' }}>{'Customer Email'}</label>
          <input
            required
            name={'UserEmail'}
            className="form-control"
            style={{ width: '100%', height: '50px' }}
            value={ paymentInfo.email }
            placeholder="user@gmail.com" />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: '14px', fontWeight: '500' }}>{'Customer Phone'}</label>
          <input
            required
            name={'UserContact'}
            className="form-control"
            style={{ width: '100%', height: '50px' }}
            value={ paymentInfo.contactNumber }
            placeholder="0123456789" />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: '14px', fontWeight: '500' }}>{'Remark'}</label>
          <input
            readOnly="readonly"
            name={'Remark'}
            className="form-control"
            style={{ width: '100%', height: '50px' }}
            value={ paymentInfo.remark || "" } />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: '14px', fontWeight: '500' }}>{'Lang'}</label>
          <input
            readOnly="readonly"
            name={'Lang'}
            className="form-control"
            style={{ width: '100%', height: '50px' }}
            value={'UTF-8'} />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: '14px', fontWeight: '500' }}>{'SignatureType'}</label>
          <input
            readOnly="readonly"
            name={'SignatureType'}
            className="form-control"
            style={{ width: '100%', height: '50px' }}
            value={'SHA256'} />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: '14px', fontWeight: '500' }}>{'Signature'}</label>
          <input
            readOnly="readonly"
            name={'Signature'}
            className="form-control"
            style={{ width: '100%', height: '50px' }}
            value={sha256(`${ keyCode.secret }${ keyCode.username }${ paymentInfo.refNo }${ props.clientInfo.environment !== 'production' ? "100" : paymentInfo.amount * 100 }MYR`)} />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: '14px', fontWeight: '500' }}>{'ResponseURL'}</label>
          <input
            readOnly="readonly"
            name={'ResponseURL'}
            className="form-control"
            style={{ width: '100%', height: '50px' }}
            value={`${ getDomainURL() }/payment-gateway/ipay88-redirect${ paymentInfo.type === 'reload' ? '?variant=reload' : '' }`} />
        </div>
        <div className="form-group" hidden>
          <label style={{ fontSize: '14px', fontWeight: '500' }}>{'BackendURL'}</label>
          <input
            readOnly="readonly"
            name={'BackendURL'}
            className="form-control"
            style={{ width: '100%', height: '50px' }}
            value={`${ getDomainURL() }/payment-gateway/ipay88-callback${ paymentInfo.type === 'reload' ? '?variant=reload' : '' }`} />
        </div>
        {
          children?.map( child => <CoreRenderFunction { ...props } item={ child } /> )
        }
      </form>
    )
  } else {
    return <></>
  }
}

export default PaymentGateway