import React, { useEffect } from "react"
import { HashRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import { registerPlugin } from 'react-filepond'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import i18next from 'i18next'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import { getClient } from 'actions/client' 
import { clearLoadingContent } from 'reducers/LoadingOverlay'
import TechBaseRouter from "router"
import CacheBuster from 'CacheBuster'

import { en } from 'locale/en'
import { zh } from 'locale/zh'
import { ms } from 'locale/ms'

import "./App.css"
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'

import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

registerPlugin( FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType )

function App(props) {
  useEffect(() => {
    props.clearLoadingContent()
    props.getClient()
  }, [])

  useEffect(() => {
    i18next.init({
      fallbackLng: 'en',
      lng: props.profile.preferred_language || 'en',
      debug: true,
      resources: {
        en,
        zh,
        ms
      }
    })
  }, props.profile.preferred_language )

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload()
        }

        return (
          <>
            <Helmet>
              <link rel="icon" href={ props.data.ClientReducer.clientInfo?.favicon?.url } sizes="16x16" />
              <title>{props.data.ClientReducer.clientInfo.name}</title>
            </Helmet>
            {
              !_.isEmpty( props.data.ClientReducer.clientInfo ) && (
                <HashRouter>
                  <TechBaseRouter />
                </HashRouter>
              )
            }
            {
              props.loadingContent.length > 0 && (
                <LoadingOverlay/>
              )
            }
          </>
        )
      }}
    </CacheBuster>
  )
}

const mapStateToProps = (state) => ({ 
  data: state,
  loadingContent: state.LoadingOverlayReducer.loadingContent,
  siteInfo: state.ClientReducer.siteInfo,
  profile: state.ProfileReducer.profile
})

export default connect(mapStateToProps, { getClient, clearLoadingContent })(App)
