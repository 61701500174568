import React from 'react'

import CoreRenderFunction from 'DisplayCore'
import { applyColor } from 'utils/apply-color'

const Div = props => {
  let {
    style,
    children,
    className,
    actionKey
  } = props.item
  return(
    <div 
      style={ applyColor( style, props.colors ) || {} } 
      className={ className || "" }
      onClick={ actionKey ? () => {
        if( typeof props.onClickAction === 'function' ) {
          props.onClickAction( actionKey )
        }
      } : () => {}}
    >
      {
        children?.map( child => {
          return <CoreRenderFunction { ...props } item={ child }/>
        })
      }
    </div>
  )
}

export default Div