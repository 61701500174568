import Axios from 'axios'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { getItem, clearItem } from 'utils/tokenStore'

import { setUserProfile, setCountry, setClientCountry, setPermissions } from 'reducers/profile'
import { setSiteModule } from 'reducers/client'
import {
  beginAjaxCall,
  ajaxCallError,
  ajaxCallSuccess
} from './Ajax'
import { clearCart, clearUpgradeCart, clearRedemptionCart } from "reducers/ShopCart"
import getDomainURL from 'utils/api'
import { getCipherHeader } from 'utils/cipher-text'
import { processSite } from 'utils/host'
import { convertObjectToBase64 } from 'utils/objToBase64'

export const getProfile = client => dispatch => {
  dispatch( beginAjaxCall() )
  Axios.defaults.headers = {
    common : {
      Authorization: getItem( 'IMPERSONATE_TOKEN' ) || getItem( 'TECHBASE_TOKEN' )
    },
    'Access-Control-Allow-Origin': '*',
    'Site': processSite( window.location.host )
  }
  Axios.get(
    `${ getDomainURL() }/auth/current_user`
  ).then( response => {
    let tmpUser = _.cloneDeep( response.data.user )
    tmpUser.client_tier = _.find( client.clientInfo.tiers, { id: tmpUser.client_user_member?.client_tier_id } )?.name || "No Tier"
    tmpUser.client_rank = _.find( client.clientInfo.ranks, { id: tmpUser.client_user_member?.client_rank_id } )?.name || "No Rank"
    dispatch( setUserProfile( tmpUser ) )
    getCountry( dispatch, 'MY' )
    if( !_.isEmpty( client.clientInfo ) ){
      getClientCountry( dispatch, client.clientInfo.id, response.data.user.country_iso2 )
    }
    getSitePermission( dispatch, client.siteInfo.id )
    getPermissions( dispatch, response.data.user.site_role_id )
    
    dispatch( ajaxCallSuccess() )
  }).catch( error => {
    if( error.response.status === 401 ){
      if( getItem( 'TECHBASE_TOKEN' ) ){
        toast.error( 'Due to inactivity, your session has expired, please login again.' )
      }
    }
    dispatch( ajaxCallError( error.response.data.message ) )
    if( getItem( 'IMPERSONATE_TOKEN' ) ){
      clearItem( 'IMPERSONATE_TOKEN' )
    } else {
      clearItem( 'TECHBASE_TOKEN' )
    }
    dispatch( clearCart() )
    dispatch( clearUpgradeCart() )
    dispatch( clearRedemptionCart() )
  })
}

const getPermissions = ( dispatch, roleID ) => {
  dispatch( beginAjaxCall() )
  Axios.defaults.headers = {
    common : {
      Authorization: getItem( 'IMPERSONATE_TOKEN' ) || getItem( 'TECHBASE_TOKEN' )
    },
    'Access-Control-Allow-Origin': '*',
    'Site': processSite( window.location.host )
  }
  Axios.get(
    `${ getDomainURL() }/site_roles/${ roleID }/site_role_modules`
  ).then( response => {
    dispatch( setPermissions( response.data ) )
    dispatch( ajaxCallSuccess() )
  }).catch( error => {
    if( error.response.status === 401 ){
      toast.error( 'Due to inactivity, your session has expired, please login again.' )
    }
    dispatch( ajaxCallError( error.response.data.message ) )
    if( getItem( 'IMPERSONATE_TOKEN' ) ){
      clearItem( 'IMPERSONATE_TOKEN' )
    } else {
      clearItem( 'TECHBASE_TOKEN' )
    }
  })
}

const getCountry = ( dispatch, countryIso2 ) => {
  dispatch( beginAjaxCall() )
  Axios.defaults.headers = {
    common : {
      Authorization: getItem( 'IMPERSONATE_TOKEN' ) || getItem( 'TECHBASE_TOKEN' )
    },
    'Access-Control-Allow-Origin': '*',
    'Site': processSite( window.location.host )
  }
  Axios.get(
    `${ getDomainURL() }/countries/${ countryIso2 }`
  ).then( response => {
    dispatch( setCountry( response.data ) )
    dispatch( ajaxCallSuccess() )
  }).catch( error => {
    dispatch( ajaxCallError( error.response.data.message ) )
  })
}

const getClientCountry = ( dispatch, clientId, countryIso2 ) => {
  dispatch( beginAjaxCall() )
  Axios.defaults.headers = {
    common : {
      Authorization: getCipherHeader()
    },
    'Access-Control-Allow-Origin': '*',
    'Site': processSite( window.location.host )
  }
  Axios.get(
    `${ getDomainURL() }/clients/${ clientId }/countries/${ countryIso2 }`
  ).then( response => {
    dispatch( setClientCountry( response.data ) )
    dispatch( ajaxCallSuccess() )
  }).catch( error => {
    dispatch( ajaxCallError( error.response.data.message ) )
  })
}

const getSitePermission = ( dispatch, siteId ) => {
  dispatch( beginAjaxCall() )
  Axios.defaults.headers = {
    'Access-Control-Allow-Origin': '*',
    'Authorization': getItem( 'IMPERSONATE_TOKEN' ) || getItem( 'TECHBASE_TOKEN' ),
    'Site': processSite( window.location.host )
  }
  Axios.get(
    `${ getDomainURL() }/site_modules/${ siteId }?query=${ convertObjectToBase64({ status: true, type: 'tree' })}`
  ).then( response => {
    dispatch( setSiteModule( response.data ) )
    dispatch( ajaxCallSuccess() )
  }).catch( error => {
    dispatch( ajaxCallError( error.response.data.message ) )
  })
}