import { ContactUsLayout } from './ContactUs'

export const ContactUsSettingsLayout = {
  content: {
    type: 'div',
    className: 'row pl-3 d-flex flex-column',
    children: [
      {
        ...ContactUsLayout.content,
        isHide: 'hideContent'
      },
      {
        type: 'div',
        isHide: 'hideContent',
        className: 'row pl-3',
        children: [
          {
            type: 'button',
            actionKey: 'CLICK_EDIT',
            buttonType: 'text',
            className: 'ml-3 custom-color',
            style: {
              backgroundColor: '$quaternary',
              color: '#ffffff'
            },
            children: [
              {
                type: 'text',
                content: "{{EDIT}}"
              }
            ]
          }
        ]
      },
      {
        type: 'card',
        isHide: 'hideEditor',
        children: [
          {
            type: 'text',
            className: 'mb-4',
            content: `<h5><strong>{{EDIT_CONTACT_US}}</strong></h5>`
          },
          {
            type: 'rte-quill-editor',
            dataKey: 'contentString',
            actionKey: 'ON_CHANGE_CONTENT'
          },
          {
            type: 'div',
            className: 'd-flex justify-content-end w-100',
            children: [
              {
                type: 'button',
                actionKey: 'UPDATE',
                buttonType: 'text',
                className: 'custom-color',
                style: {
                  backgroundColor: '$quaternary',
                  color: '#ffffff'
                },
                children: [
                  {
                    type: 'text',
                    content: "{{UPDATE}}"
                  }
                ]
              },
              {
                type: 'button',
                actionKey: 'CANCEL_EDIT',
                buttonType: 'text',
                className: 'custom-color ml-2',
                style: {
                  backgroundColor: '$quaternary',
                  color: '#ffffff'
                },
                children: [
                  {
                    type: 'text',
                    content: "{{CANCEL}}"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
}
