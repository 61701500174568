import React from "react"
import _ from 'lodash'

import WithHOC from "./action"
import CoreRenderFunction from "DisplayCore"
import { ForgotPasswordLayout } from "PageLayout/ForgotPassword"

const ForgotPassword = props => {

  const hideComponent = ( actionKey ) => {
    switch( actionKey ){
      case 'isPasswordResetPage':
        return !_.isEmpty( props.token )
      default:
        return
    }
  }

  return (
    <CoreRenderFunction 
      { ...props } 
      item={ ForgotPasswordLayout }
      hideComponent={ hideComponent }/>
  )
}

export default WithHOC( ForgotPassword )