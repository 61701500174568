import React from 'react'
import { LineChart, Line, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts'

import { applyColor } from 'utils/apply-color'

const TechBaseChartContent = props => {
  let {
    lines,
    width,
    height,
    data,
    config
  } = props.item
  
  return(
    <ResponsiveContainer width={ width } height={ height }>
      <LineChart data={ data }>
        {
          lines?.map( line => (
            <Line { ...applyColor( line, props.colors ) } />
          ))
        }
        <CartesianGrid { ...config.CartesianGrid } />
        <XAxis { ...config.xAxis } />
        <YAxis { ...config.yAxis }/>
      </LineChart>
    </ResponsiveContainer>
  )
}

export default TechBaseChartContent