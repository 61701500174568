import React from 'react'
import ReactHTMLParser from 'react-html-parser'
import Moment from 'moment'

import { applyColor } from 'utils/apply-color'
import { getSelectedLanguageContent, handleNestedObjectKey, truncateTextWithEllipsis } from 'utils'
import _ from 'lodash'

const TechBaseText = props => {
  let {
    content,
    dataKey,
    style,
    className,
    variant,
    suffix,
    prefix,
    decimalPoint,
    showEmptyData,
    maxChars,
    shortform,
    showShortForm
  } = props.item

  const getContent = () => {
    if( dataKey ){
      let tmpData = handleNestedObjectKey( props, dataKey )
      if( _.isEmpty( tmpData ) && typeof tmpData !== 'number' && !showEmptyData ){
        return ''
      }
      if( variant === "price" && typeof tmpData === "number" ){
        tmpData = tmpData.toFixed(2)
        if( !_.isEmpty( tmpData ) ){
          tmpData = `${ tmpData.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        }
      }
      if( typeof tmpData === "number" && variant === "number" ){
        tmpData = tmpData.toFixed( decimalPoint || 0 )
      }
      if( variant === "date" && Moment( tmpData ).isValid() && typeof tmpData !== "number" ){
        tmpData = Moment( tmpData ).format( 'YYYY-MM-DD' )
      }
      tmpData = `${ getSelectedLanguageContent( handleNestedObjectKey( props, prefix ) || prefix, props.selectedLanguage ) } ${ getSelectedLanguageContent( tmpData, props.selectedLanguage ) } ${ getSelectedLanguageContent( handleNestedObjectKey( props, suffix ) || suffix, props.selectedLanguage ) }`
      if( content ){
        let tmp = getSelectedLanguageContent( content, props.selectedLanguage )
        return tmp.replaceAll("${PLACEHOLDER}", tmpData)
      } else {
        return getSelectedLanguageContent( tmpData || '-', props.selectedLanguage )
      }
    } else if( shortform && typeof showShortForm === 'function' && showShortForm()) {
      return getSelectedLanguageContent( shortform, props.selectedLanguage )
    } else {
      return getSelectedLanguageContent( content, props.selectedLanguage )
    }
  }
  
  return (
    <div 
      style={ applyColor( style, props.colors ) }
      className={ className || '' }
    >
      {
        ReactHTMLParser( truncateTextWithEllipsis( applyColor( getContent(), props.colors ), maxChars ) )
      }
    </div>
  )
}

export default TechBaseText