export const ForgotSecurityPinLayout = {
  type: "div",
  style: { backgroundColor: "#ffffff" },
  children: [
    {
      type: "div",
      className: "row justify-content-center align-items-center",
      style: { minHeight: "95vh" },
      children: [
        {
          type: "div",
          className: "p-5",
          style: { backgroundColor: "#00000055", borderRadius: "10px" },
          children: [
            {
              type: "div",
              className: "text-center mb-5",
              style: { width: "100%" },
              children: [
                {
                  type: "img",
                  style: { width: "200px" },
                  altText: "client-logo"
                }
              ]
            },
            {
              type: "form",
              onFinish: "onSubmitForm",
              children: [
                {
                  type: "div",
                  children: [
                    {
                      type: "text",
                      content: "<h6><strong>{{ENTER_NEW_SECURITY_PIN}}</strong></h6>"
                    },
                    {
                      type: "input",
                      variant: "password",
                      rules:[{ required: true, message: "{{PLEASE_ENTER_YOUR}} {{NEW_SECURITY_PIN}}" }],
                      label: "{{NEW_SECURITY_PIN}}",
                      name: "security_pin",
                      inputConfig: {
                        placeholder: "{{ENTER}} {{NEW_SECURITY_PIN}}",
                        size: "large"
                      }
                    },
                    {
                      type: "input",
                      variant: "password",
                      rules:[
                        { required: true, message: "{{PLEASE_ENTER_YOUR}} {{CONFIRM_SECURITY_PIN}}" }, 
                        { equal: "security_pin", message: "{{SECURITY_PIN_CONFIRMATION_ERROR}}" }
                      ],
                      label: "{{CONFIRM_SECURITY_PIN}}",
                      name: "confirm_security_pin",
                      inputConfig: {
                        placeholder: "{{ENTER}} {{CONFIRM_SECURITY_PIN}}",
                        size: "large"
                      }
                    }
                  ]
                },
                {
                  type: "div",
                  className: "d-flex justify-content-end",
                  style: {minWidth: "270px"},
                  children: [
                    {
                      type: "button",
                      size: "large",
                      htmlType: "submit",
                      buttonType: "text",
                      className: "custom-color my-3",
                      style: { backgroundColor: "$quaternary", color: "#ffffff" },
                      children: [
                        {
                          type: "text",
                          content: "<div>{{SUBMIT}}</div>"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      type: "footer",
      style: { minHeight: "5vh" }
    }
  ]
}