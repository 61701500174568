import React from 'react'
import { connect } from 'react-redux'

import CoreRenderFunction from 'DisplayCore'
import { paymentPendingLayout } from 'PageLayout/Registration/PaymentPending'

const PaymentPending = props => {
  
  return(
    <CoreRenderFunction 
      { ...props } 
      item={ paymentPendingLayout.content }/>
  )
}

const mapStateToProps = state => ({
  data: state,
  colors: state.ClientReducer.clientInfo.styles?.colors,
  selectedLanguage: state.ProfileReducer.profile.preferred_language
})

export default connect( mapStateToProps  )( PaymentPending )