import React from "react"
import { Card } from "antd"

import CoreRenderFunction from "DisplayCore"
import { applyColor } from 'utils/apply-color'

const TechBaseCard = props => {
  let {
    extra,
    style,
    title,
    children,
    className,
    headStyle,
    bodyStyle
  } = props.item

  return(
    <Card 
      style={ applyColor( style, props.colors ) || {} }
      headStyle={ applyColor( headStyle, props.colors ) || {} }
      bodyStyle={ applyColor( bodyStyle, props.colors ) || {} }
      extra={ extra && <CoreRenderFunction { ...props } item={ extra }/> }
      title={ title && <CoreRenderFunction { ...props } item={ title }/> }
      className={ className }>
      {
        children?.map( child => {
          return <CoreRenderFunction { ...props } item={ child }/>
        })
      }
    </Card>
  )
}

export default TechBaseCard
