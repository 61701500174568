import React, { useEffect, useState } from 'react'
import { Form, Checkbox } from 'antd';

import { getSelectedLanguageContent, handleNestedObjectKey } from 'utils'

const TechBaseCheckbox = props => {
  let{
    name,
    style,
    className,
    label,
    actionKey,
    inputConfig,
    indeterminate,
    checkedKey
  } = props.item
  const [ checked, setChecked ] = useState( undefined )

  useEffect(() => {
    let checkedData = handleNestedObjectKey( props, checkedKey )
    setChecked( checkedData )
  }, [ handleNestedObjectKey( props, checkedKey ) ])
  
  return (
    <Form.Item
      name={ name }
      style={ style }
      className={ className }
      initialValue={ inputConfig?.initialValue ? inputConfig.initialValue : false }
      valuePropName='checked'
    >
      <Checkbox
        {...checked ? {checked: checked} : {}}
        indeterminate={ handleNestedObjectKey( props, indeterminate ) }
        onChange={ e => {
          if( props.onClickAction ) {
            props.onClickAction( actionKey, e.target.checked, props )
          }
        }}
      >
        { getSelectedLanguageContent( label ) }
      </Checkbox>
    </Form.Item>
  )
}

export default TechBaseCheckbox
