export const paymentPendingLayout = {
  content: {
    type: "div",
    children: [
      {
        type: "div",
        children: [
          {
            type: "div",
            className: "d-flex m-auto",
            style: {
              width: "70vw",
              padding: "50px 0",
              height: "100vh"
            },
            children: [
              {
                type: "card",
                className: "text-center m-auto w-100 py-5",
                children: [
                  {
                    type: 'icon',
                    antIcon: 'WarningFilled',
                    style: {
                      marginRight: '6px',
                      marginBottom: '6px',
                      fontSize: "60px",
                      color: "#FFC107"
                    }
                  },
                  {
                    type: "text",
                    content: "<h6><strong>{{PAYMENT_PENDING}}</strong></h6>"
                  },
                  {
                    type: "text",
                    content: "<div style=\"color: #aaaaaa\">{{YOUR_ORDER_PAYMENT_IS_PENDING}}.</div>"
                  },
                  {
                    type: "text",
                    content: "<div style=\"color: #aaaaaa\">{{PLEASE_WAIT_PATIENTLY}}.</div>"
                  },
                  {
                    type: "div",
                    className: "mt-5 mx-auto d-flex justify-content-center",
                    children: [
                      {
                        type: "button",
                        size: "large",
                        href: "/#/login",
                        buttonType: "text",
                        className: "custom-color href-button mr-2",
                        style: { backgroundColor: "$quaternary", color: "!quaternary", textAlign: "center" },
                        children: [
                          {
                            type: "text",
                            content: "<div>{{BACK_TO_LOGIN_PAGE}}</div>"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
}