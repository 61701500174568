import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import { getSelectedLanguageContent, handleNestedObjectKey } from 'utils'

import 'react-quill/dist/quill.snow.css'

const TechBaseQuillEditor = props => {
  let {
    dataKey,
    config,
    actionKey
  } = props.item
  const [ colorOptions, setColorOptions ] = useState([])
  const PRE_DEFINED_MODULES = {
    toolbar: [
      [{ 'header': [ 1, 2, false ]}],
      [{ 'size': []}],
      [ 'bold', 'italic', 'underline', 'strike', 'blockquote' ],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'color': [ ...colorOptions ]}, { 'background': [ ...colorOptions ]}],
      [ 'link' ],
      [ 'clean' ]
    ]
  }

  const PRE_DEFINED_FORMATS = [
    'header', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'color', 'background',
    'link', 'image'
  ]

  useEffect(() => {
    if( _.isEmpty( colorOptions )) {
      setColorOptions([
        props.colors.primary,
        props.colors.secondary,
        props.colors.quaternary,
        props.colors.tertiary
      ])
    }
  }, [ props.colors ])

  const getContent = () => {
    if( dataKey ){
      let tmpData = handleNestedObjectKey( props, dataKey )

      return `${ getSelectedLanguageContent( tmpData, props.selectedLanguage )}`
    } else {
      return '<p><br></p>'
    }
  }

  return (
    <div className='text-editor'>
      {
        !_.isEmpty( colorOptions ) && (
          <ReactQuill
            theme='snow'
            modules={ config?.modules || PRE_DEFINED_MODULES }
            formats={ config?.formats || PRE_DEFINED_FORMATS }
            value={ getContent()}
            onChange={ value => {
              if ( typeof props.onClickAction === 'function' ) {
                props.onClickAction( actionKey, value ) 
              }
            }}
          >
          </ReactQuill>
        )
      }
      
    </div>
  
  )
}

export default TechBaseQuillEditor
