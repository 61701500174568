import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import CoreRenderFunction from 'DisplayCore'
import { removePendingPayment } from "reducers/ShopCart"
import { paymentFailedLayout } from 'PageLayout/Registration/PaymentFailed'

const PaymentFailed = props => {
  useEffect(() => {
    props.removePendingPayment( { value: window.location.hash.split('/').at(-1), status: false } )
  }, [])
  
  return(
    <CoreRenderFunction 
      { ...props } 
      item={ paymentFailedLayout.content }/>
  )
}

const mapStateToProps = state => ({
  data: state,
  colors: state.ClientReducer.clientInfo.styles?.colors,
  selectedLanguage: state.ProfileReducer.profile.preferred_language
})

export default connect( mapStateToProps, { removePendingPayment }  )( PaymentFailed )