import './index.scss'

export const ContactUsLayout = {
  content: {
    type: 'div',
    className: 'row mb-4 pt-5 pl-3',
    children: [
      {
        type: 'div',
        className: 'col-12',
        children: [
          {
            type: 'text',
            content: `<h1>{{CONTACT_US}}</h1>`
          }
        ]
      },
      {
        type: 'div',
        className: 'col-12 pt-3',
        children: [
          {
            type: 'text',
            className: 'lbb-contact-us',
            content: '${PLACEHOLDER}',
            dataKey: 'contentString'
          },
          {
            type: 'a',
            isHide: 'hideDownloadBAForm',
            href: '$BAForm',
            download: "LBB-BA-Application-Form_2024.pdf",
            target: "_blank",
            rel: "noopener noreferrer",
            children: [
              {
                type: 'button',
                buttonType: 'text',
                className: 'mt-3 custom-color',
                style: {
                  backgroundColor: '$quaternary',
                  color: '#ffffff'
                },
                children: [
                  {
                    type: 'text',
                    content: "{{DOWNLOAD_BA_APPLICATION_FORM}}"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
}
