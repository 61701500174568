import React from 'react'
import { Button } from 'reactstrap'

import TechBaseModal from 'components/Modal'
import TechBaseModalBody from 'components/Modal/body'
import LanguageSwitcher from 'components/LanguageSwitcher'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import { getSelectedLanguageContent } from "utils"
import { applyColor } from 'utils/apply-color'

export default function KYCStatusModal({ 
  data,
  colors, 
  updateShowKYC, 
  showStatusModal, 
  updateShowStatusModal 
}) {

  return (
    <TechBaseModal isOpen={ showStatusModal } style={{ maxWidth: '500px' }}>
      <TechBaseModalBody>
        <div style={{ float: 'right' }}>
          <span className='mr-2'>{ getSelectedLanguageContent("{{LANGUAGE}}") }</span>
          <LanguageSwitcher />
        </div>
        <div className='text-center m-5'>
          {
            data.ProfileReducer.profile.status === 'pending-verification' ? (
              <>
                <i class='fa fa-check-circle' style={{ color:'#3a811d', fontSize: '100px' }}></i>
                <p className='my-4'>{ getSelectedLanguageContent("{{PENDING_VERIFICATION_MESSAGE}}") }</p>
              </>
            ) : (
              <>
                <i class='fa fa-exclamation-triangle' style={{ color:'#ed4134', fontSize: '100px' }}></i>
                <p className='my-4'>{ getSelectedLanguageContent("{{VERIFICATION_FAILED_MESSAGE}}") }</p>
              </>
            )
          }
          {
            data.ProfileReducer.profile.status === 'verification-failed' && (
              <Button
                style={{
                  border: 'none',
                  background: applyColor( '$quaternary', colors ),
                  width: '110px',
                  marginRight: '10px'
                }}
                onClick={() => {
                  updateShowKYC( true )
                  updateShowStatusModal( false )
                }}
              >
                { getSelectedLanguageContent("{{REUPLOAD_IC}}") }
              </Button>
            )
          }
          <Button
            style={{
              border: 'none',
              background: applyColor( '$secondary', colors ),
              color: applyColor( '$quaternary', colors ),
              width: '110px'
            }}
            onClick={ () => updateShowStatusModal( false ) }
          >
            { getSelectedLanguageContent("{{CLOSE}}") }
          </Button>
        </div>
      </TechBaseModalBody>
      { data.LoadingOverlayReducer.loadingContent.length > 0 && <LoadingOverlay /> }
    </TechBaseModal>
  )
}
